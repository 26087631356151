<template>
  <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess"
    :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
  >
    <AltStatus
      :blnalert="blnAltStatus"
      icon="CorrectFill"
      coloricon="fill-success"
      class="success"
      :name="$t('28')"
    />
    <AltStatus
      :blnalert="blnAltStatusunsucess"
      icon="IncorrectFill"
      coloricon="fill-danger"
      class="error"
      :name="$t('174')"
      @mousemove="hover = true"
      @mouseleave="hover = false"
    />
  </div>
  <Alert
    idName="ModalDlt"
    artIcon="DeleteFill"
    :altMsgBox="$t('186', { fieldname: $t(nametable).toLowerCase() })"
    @onInput="confirmDelete($event)"
    classIcon="fill-danger"
  />

  <div
    v-if="progressScore <= 99"
    class="d-flex align-items-center w-100 justify-content-center"
  >
    <Loading1 v-if="$route.params.id == 1" :percen="progressScore" />
    <Loading2 v-if="$route.params.id == 2 || $route.params.id == null" />
    <Loading3 v-if="$route.params.id == 3" />
    <Loading4 v-if="$route.params.id == 4" :percen="progressScore" />
  </div>
  <div
    class="overlay"
    :class="filter ? ' overlay-in' : 'overlay-out'"
    v-if="filter"
    @click="filter"
  ></div>
  <!-- <transition name="slide">
    <ConfigDisplay
      :DefaultField="ListDataTable.defaultField"
      :AllField="ListDataTable.dataFields"
      :SelectedListExcept="ListDataTable.selectedListExcept"
      :SelectedListOld="SelectedList"
      ref="config"
      v-if="showDisplay == true"
      @Ok="onSaveDisplay($event)"
      @Cancel="onShowConfigDisplay()"
    />
  </transition> -->
  <div
    v-show="progressScore >= 99"
    class="pt-4 px-4 bg-white rounded-2 shadow-sm con"
  >
    <!-- <label class="fnt-regular fnt-subtitle pb-2">System word list </label> -->
    <div class="pb-2">
      <InputSearch
        :ref="'Search'"
        label="Search"
        class="w-100"
        title=""
        hint="Search"
        @onSearch="SearchListDataTable($event)"
        @onClean="SearchListDataTable('')"
      />
    </div>
    <div
      class="table-container"
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousedown="onTabledown"
      @mousemove="ontableScrollMove"
      :style="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'height:fit-content;'
          : ''
      "
      :class="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'none-scrollbar'
          : ''
      "
    >
      <!-- <button class="btn btn-primary" @click="checkWidth">
          Check width of table
        </button> -->
      <table class="table" aria-describedby="tableSystemword">
        <thead>
          <tr>
            <th scope="col" class="fnt-body" style="width: 0">
              <div class="d-flex align-items-center justify-content-center">
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                  {{ $t("18") }}
                </label>
              </div>
            </th>
            <th
              v-for="(items, index) in SelectedList"
              :key="index"
              scope="col"
              class="fnt-body"
            >
              <!-- <button class="btn fnt-body text-black-500 p-0 m-0" @click="listsortData(SelectedList[index].field, sort)"  > 
              {{ $t(items.head) }}
              <button/> -->
              <div class="d-flex align-items-center">
                <button
                  id="sortColumn"
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  :class="
                    checkTableSort(SelectedList[index].field)
                      ? 'text-black'
                      : ''
                  "
                >
                  {{ $t(items.head) }}
                </button>
                <!-- <Button
                leftBtn="bi:arrow-down"
                classBtn="p-0 m-0 mb-1 sort text-black-500 ms-1 sorting-active"
                :class="checkTableSort(campus.state[index-1]) ? 'sorted text-black' : 'd-none'"
                width="14"
                height="14"
                class="arrow-sort"
                :class="
                  checkTableSort(SelectedList[index].field)
                    ? 'sorted text-black'
                    : 'd-none'
                "
                id="sortColumn"
                @click="listsortData(SelectedList[index].field, sort)"
              /> -->
                <button
                  id="sortColumn"
                  :class="
                    sort && checkTableSort(SelectedList[index].field, sort)
                      ? 'text-secondary sorted'
                      : 'hover-none text-secondary '
                  "
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="fnt-body btn p-0 m-0 sorting-active"
                >
                  <!-- <Icon
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : 'opacity-0'
                    "
                    icon="bi:arrow-down"
                    width="14"
                    height="14"
                  /> -->
                  <SortArrow
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : ' opacity-0 '
                    "
                    width="18"
                    height="18"
                  />
                </button>
              </div>
            </th>
            <th scope="col" style="height: 48px; width: 0">
              <!-- <div class="d-flex justify-content-start">
                <Button
                  icon="DisplayConfig"
                  classBtn="stroke-secondary p-0 m-0"
                  @click="onShowConfigDisplay()"
                  width="20"
                  height="20"
                />
              </div> -->
            </th>
          </tr>
        </thead>
        <tbody class="border-0" v-if="listSortData.length != 0">
          <tr
            v-for="(items, index) in listSortData"
            :key="index"
            class="border-bottom"
          >
            <th v-if="page == 1" id="">
              <div
                class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
              >
                {{ index + 1 }}
              </div>
            </th>
            <th v-if="page != 1" id="">
              <div
                class="d-flex align-items-center fnt-regular fnt-body justify-content-center mt-1"
              >
                {{ (page - 1) * perpage + (index + 1) }}
              </div>
            </th>

            <td
              v-for="(itemsState, indexState) in SelectedList"
              :key="indexState"
              class="fnt-regular fnt-body"
            >
              <div
                v-if="
                  SelectedList[indexState].field == 'intRequire' ||
                  SelectedList[indexState].field == 'intIdentifier' ||
                  SelectedList[indexState].field == 'intRepeatable' ||
                  SelectedList[indexState].field == 'intNotnull'
                "
                class="d-flex align-items-center mt-1"
              >
                <Icon
                  v-if="items[SelectedList[indexState].field] == 1"
                  icon="bi:check-lg"
                  class="text-success"
                  width="24"
                />
                <div v-if="items[SelectedList[indexState].field] == 0">
                  {{ "-" }}
                </div>
              </div>
              <div
                v-if="SelectedList[indexState].field == 'blnUsed'"
                class="d-flex align-items-center mt-1"
              >
                <Icon
                  v-if="items[SelectedList[indexState].field] == 1"
                  icon="bi:check-lg"
                  class="text-success"
                  width="24"
                />
                <div v-if="items[SelectedList[indexState].field] == 0">
                  {{ "-" }}
                </div>
              </div>
              <div v-if="SelectedList[indexState].field == 'strDatatype'">
                {{
                  $t(ConvertUserField(items[SelectedList[indexState].field]))
                }}
              </div>

              <div v-if="SelectedList[indexState].field == 'intFieldType'">
                {{
                  $t(
                    ConvertUserFieldType(items[SelectedList[indexState].field])
                  )
                }}
              </div>
              <div v-if="SelectedList[indexState].field == 'intValuetype'">
                {{
                  $t(
                    ConvertParameterValueType(
                      items[SelectedList[indexState].field]
                    )
                  )
                }}
              </div>

              <div
                v-if="
                  SelectedList[indexState].field != 'blnUserTypeStatus' &&
                  SelectedList[indexState].field != 'blnCampusStatus' &&
                  SelectedList[indexState].field != 'intPositionStatus' &&
                  SelectedList[indexState].field != 'intEducationStatus' &&
                  SelectedList[indexState].field != 'intTemplatestatus' &&
                  SelectedList[indexState].field != 'intPositionWorkPeriod' &&
                  SelectedList[indexState].field != 'strDatatype' &&
                  SelectedList[indexState].field != 'intFieldType' &&
                  SelectedList[indexState].field != 'intRequire' &&
                  SelectedList[indexState].field != 'intIdentifier' &&
                  SelectedList[indexState].field != 'intRepeatable' &&
                  SelectedList[indexState].field != 'intValuetype' &&
                  SelectedList[indexState].field != 'intNotnull' &&
                  SelectedList[indexState].field != 'blnUsed'
                "
                class="d-flex align-items-center mt-1"
              >
                {{
                  items[SelectedList[indexState].field] == null
                    ? "-"
                    : items[SelectedList[indexState].field]
                }}
                <Label
                  v-if="
                    checkmainListDataTable(
                      SelectedList[indexState].field,
                      items
                    )
                  "
                  class="main-data text-primary m-0 ms-1 ps-1 p-0"
                  :label="
                    SelectedList[indexState].field == 'strCampusNameEN'
                      ? ' (' + $t('21') + ')'
                      : SelectedList[indexState].field == 'strUsertemplatename'
                      ? ' (' + $t('154') + ')'
                      : ''
                  "
                />
              </div>
              <div
                v-if="SelectedList[indexState].field == 'intPositionWorkPeriod'"
                class="d-flex align-items-center mt-1"
              >
                {{ PositionWorkPeriod(items[SelectedList[indexState].field]) }}
              </div>
              <div
                v-if="SelectedList[indexState].field == 'blnCampusStatus'"
                class="d-flex align-items-center mt-1"
              >
                <Status
                  v-if="
                    items[SelectedList[indexState].field] == 0 ||
                    items[SelectedList[indexState].field] == false
                  "
                  :name="$t('62')"
                  labelclass="bg-black-500 "
                />
                <Status
                  v-if="
                    items[SelectedList[indexState].field] == 1 ||
                    items[SelectedList[indexState].field] == true
                  "
                  :name="$t('61')"
                  labelclass="bg-success"
                />
              </div>
              <div
                v-if="
                  SelectedList[indexState].field == 'intPositionStatus' ||
                  SelectedList[indexState].field == 'intEducationStatus' ||
                  SelectedList[indexState].field == 'intTemplatestatus'
                "
                class=""
              >
                <Status
                  v-if="items[SelectedList[indexState].field] == 0"
                  :name="$t('90')"
                  labelclass="bg-black-500 "
                />
                <Status
                  v-if="items[SelectedList[indexState].field] == 1"
                  :name="$t('89')"
                  labelclass="bg-success"
                />
              </div>
            </td>
            <th style="width: fit-content" id="">
              <div class="d-flex align-items-center">
                <Button
                  icon="EditFill"
                  classBtnIcon="fill-secondary p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="editdata(items['strLDPID'])"
                  classBtn="p-0 m-0"
                  class="pe-3"
                />

                <!-- <Button
                  v-if="items['candelete'] == true"
                  icon="DeleteFill"
                  classBtnIcon="fill-secondary"
                  class="p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  dataToggle="modal"
                  @click="intID = items['strID']"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->

                <!-- ลบข้อมูลไม่ได้ ปุ่มเป็นสีเทา -->
                <!-- <Button
                  v-if="
                    items['candelete'] == false &&
                    items['intFieldType'] == null &&
                    items['intDefaultset'] != 1
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500 "
                  class="tooltip-config p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="intID = items['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('24')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
                <!-- <Button
                  v-if="
                    items['candelete'] == false && items['intFieldType'] == 1
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500"
                  class="tooltip-config p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="intID = items['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('149')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
                <!-- <Button
                  v-if="
                    items['candelete'] == false &&
                    items['intFieldType'] != 1 &&
                    items['intFieldType'] != null
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500"
                  class="tooltip-config p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="intID = items['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('149')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
                <!-- <Button
                  v-if="
                    items['candelete'] == false && items['intDefaultset'] == 1
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500"
                  class="tooltip-config p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="intID = items['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('163')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
                <!-- ลบข้อมูลไม่ได้ ปุ่มเป็นสีเทา -->
                <!-- <Button
                  v-if="items['blnCampusStatus'] == 1"
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500 "
                  class="tooltip-config p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  dataToggle="tooltip"
                  @click="intID = items['strID']"
                  :tooltipMsg="checkUIDelete(null, items) ? $t('25') : $t('24')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
                <tooltip icon="fluent:delete-24-filled" />
              </div>
            </th>
          </tr>
        </tbody>
      </table>
      <NotFound
        v-if="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
        "
        :style="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
            ? 'height:fit-content;'
            : ''
        "
        class="border-bottom w-100 notfound"
        :name="nametable"
      />
    </div>
    <Pagination
      ref="Pagination"
      :v-bind="ListDataTable"
      class="col-12"
      :total="datatotal"
      :datatotal="listSortData.length"
      :total-pages="currentPage"
      :perPage="perpage"
      :currentPage="page"
      @pagechanged="onPageChange($event)"
      @perpage="perpageChnage($event)"
    />
  </div>
</template>

<script>
import { SortArrow } from "@/components/icon/index";
// import ConfigDisplay from "../configDisplay/configDisplay.vue";
import Label from "../label/label.vue";
import Status from "../label/statusLabel.vue";
import NotFound from "../notFound/notFound.vue";
import AltStatus from "../alert/alertStatus.vue";
import Alert from "../alert/alert.vue";
// import AlertEdt from "../alert/alertDuplicated.vue";
import Pagination from "../pagination/pagination.vue";

import Button from "../button/button.vue";

import { Icon } from "@iconify/vue";
import Enumerable from "linq";
import Loading1 from "@/components/Loading.vue";
import Loading2 from "@/components/Skeleton.vue";
import Loading3 from "@/components/waveloading.vue";
import Loading4 from "@/components/progress.vue";
import InputSearch from "@/components/input/inputSearch.vue";
export default {
  name: "Table",
  props: {
    ListDataTable: {},
    filter: { type: Boolean },
    firstSort: { type: String },
    secondSort: { type: String },
    nametable: { type: String },
    progressScore: { type: Number },
    blnSearchAll: { type: Boolean, default: false },
  },
  emits: ["edit", "delete", "ConfigDisplay", "onSaveConfigDisplay"],
  data() {
    return {
      showListDataTable: true,
      progress: 0,
      sort: false,
      blnAltStatus: false,
      blnAltStatusunsucess: false,
      perpage: 20,
      page: null,
      listSortData: [],
      currentData: [],
      intID: null,
      currentPage: null,
      datatotal: null,
      fieldSort: null,
      SelectedList: [],
      showDisplay: false,
      activeFilters: [],
      isDown: null,
      startX: Number,
      scrollLeft: Number,
      hover: false,
      timerCount: 0,
      timerEnabled: false,
      listSearch: [],
      strKeywords: "",
    };
  },

  components: {
    Loading1,
    Loading2,
    Loading3,
    Loading4,
    Pagination,
    Button,
    Alert,
    AltStatus,
    NotFound,
    Status,
    Label,
    // ConfigDisplay,
    SortArrow,
    Icon,
    InputSearch,
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  methods: {
    onTabledown(e) {
      this.isDown = true;
      const slider = document.querySelector(".table-container");
      const startX = e.pageX - slider.offsetLeft;
      const scrollLeft = slider.scrollLeft;
      this.startX = startX;
      this.scrollLeft = scrollLeft;
    },

    ontableScrollMove(e) {
      const slider = document.querySelector(".table-container");
      if (this.isDown == true) {
        e.preventDefault();
        this.isDown = true;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 1; //scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
      } else {
        this.isDown = false;
      }
    },

    onSaveDisplay(data) {
      this.$emit("onSaveConfigDisplay", data);
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },

    onShowConfigDisplay() {
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },
    //เปลี่ยนจำนวนข้อมูลต่อ 1 หน้า
    perpageChnage(perPage) {
      this.perpage = perPage;
      //คำนวน จำนวนหน้าทั้งหมด
      this.currentPage = Math.ceil(this.datatotal / perPage);
      this.page = 1;

      //ย้อนกลับไปหน้าแรก
      this.$refs.Pagination.currentPage = 1;
      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        1,
        this.currentData
      );
    },

    //เปลียนหน้า
    onPageChange(page) {
      this.page = page;

      //เรียงลำดับของข้อมูล
      if (
        this.strKeywords == null ||
        this.strKeywords == undefined ||
        this.strKeywords == ""
      ) {
        this.listSortData = this.$refs.Pagination.renderTableData(
          page,
          this.currentData
        );
      } else {
        this.listSortData = this.$refs.Pagination.renderTableData(
          page,
          this.listSearch
        );
      }
    },

    async setSelectedList(data) {
      if (data.isTrusted != true) {
        this.SelectedList = data;
      } else if (data.isTrusted == true) {
        this.SelectedList = this.ListDataTable.defaultField;
      }

      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        console.log("sort");
        this.fieldSort = this.firstSort;
        this.sort = false;
      } else if (s.length == 0) {
        console.log("sort 1");
        this.fieldSort = this.secondSort;
        this.sort = false;
      }
      return true;
    },

    //คำนวนจำนวนหน้า และจัดเรียงข้อมูล โดยค่าเริ่มต้นเป็น 20 ข้อมูล ต่อ 1 หน้า
    calculatePage(total, data) {
      this.datatotal = total;

      this.currentPage = Math.ceil(total / this.perpage);
      this.page = 1;
      console.log(this.currentPage);
      this.listSortData = this.$refs.Pagination.renderTableData(1, data);
    },

    //ค้นหน้าข้อมูลจาก list ที่เก็บไว้ในเครื่อง
    SearchListDataTable(event) {
      this.strKeywords = event;
      if (event != null || event != "") {
        this.listSortData = this.filterSelected(
          this.currentData.slice(),
          this.SelectedList,
          event
        );
        this.listSearch = this.listSortData;
      }
      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        console.log("sort");
        this.fieldSort = this.firstSort;

        // this.listSortData = SortArray.multiplyArraySort(
        //   this.listSortData,
        //   this.firstSort,
        //   this.secondSort
        // );
        this.calculatePage(this.listSortData.length, this.listSortData);

        this.sort = false;
      } else if (s.length == 0) {
        console.log("sort 1");

        this.fieldSort = this.secondSort;

        // this.listSortData = SortArray.SingleArraySort(
        //   this.listSortData,
        //   this.fieldSort
        // ).reverse();
        this.calculatePage(this.listSortData.length, this.listSortData);
        this.sort = true;
      }
    },

    filterSelected(array, filters, event) {
      // console.log(array);
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // validates all filter criteria
        return filterKeys.some((key) => {
          // console.log(item[filters[key].field]);
          // ignores non-function predicates
          if (
            item[filters[key].field] !== null &&
            (this.blnSearchAll == false
              ? filters[key].field == "strLDPTH" ||
                filters[key].field == "strLDPEN"
              : filters[key].field !== null)
          ) {
            return (
              item[filters[key].field]
                .toString()
                .toLowerCase()
                .indexOf(event.toString().toLowerCase()) !== -1
            );
          }
        });
      });
    },

    //ยกเลิกการค้นข้อมูล
    onClean() {
      this.listSortData = this.ListDataTable.data;
      this.currentData = this.ListDataTable.data;
      this.setSelectedList(this.SelectedList);
      this.calculatePage(this.listSortData.length, this.currentData);
    },
    //ตรวจสอบสถานะการเรียงข้อมูล
    checkTableSort(field) {
      if (field == this.fieldSort) {
        return true;
      } else if (field != this.fieldSort) {
        return false;
      }
    },
    //เรียงข้อมูล จากหัวตาราง
    listsortData(field, it, s) {
      this.fieldSort = field;
      if (field == null) {
        return false;
      }

      if (it === false) {
        s = true;
      }

      //sort A->Z
      if (s == true) {
        if (this.fieldSort !== this.firstSort) {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) => r[this.fieldSort])

            .toArray();
          this.sort = false;
          this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) => r[this.fieldSort])

            .toArray();
          this.sort = false;
          this.currentData = reversed;
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
      //sort Z->A
      if (s == false) {
        if (this.fieldSort !== this.firstSort) {
          this.currentData = Enumerable.from(this.currentData)
            .orderByDescending((r) => r[this.fieldSort])
            .toArray();
          // var reversed = SortArray.SingleArraySort(
          //   this.currentData,
          //   this.fieldSort
          // );
          //this.currentData = reversed.reverse();
          this.listSortData = this.currentData;
          this.sort = true;
          this.calculatePage(this.listSortData.length, this.currentData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) => r[this.fieldSort])

            .toArray();
          this.sort = true;
          this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
    },

    //ตรวจ วิทยาเขต
    checkmainListDataTable(field, data) {
      if (
        (field == "strCampusNameEN" && data["intCampusMain"] == 1) ||
        (data["intDefaultset"] == 1 && field == "strUsertemplatename")
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkUIDelete(field, data) {
      if (field == null) {
        if (data["intCampusMain"] == 1) {
          return true;
        } else {
          return false;
        }
      }
    },

    editdata(id) {
      console.log(id);
      this.$emit("edit", id);
    },
    deleteListDataTable() {
      return true;
    },

    confirmDelete(state) {
      if (state == true) {
        this.$emit("delete", this.intID);
      } else if (state == false) {
        return false;
      }
    },
    checkstateofdelete(state) {
      this.timerEnabled = false;
      this.timerCount = 0;
      if (state == true) {
        setTimeout(() => {
          this.checkstateofalert();
        }, 2000);
        this.blnAltStatus = true;
        setTimeout(() => {
          this.blnAltStatus = false;
        }, 2000);
        return true;
      } else if (state == false) {
        setTimeout(() => {
          this.checkstateofalert();
        }, 2000);
        this.blnAltStatusunsucess = true;

        this.timerEnabled = true;
        return true;
      }
    },
    //แสดงข้อมูลบนตาราง
    renderTableData(req_per_page, page_no) {
      var all_data = this.ListDataTable.data;

      var resp;
      //เรียกข้อมูลใน array จากลำดับเริ่มต้นไปยังอีกจุด
      resp = all_data.slice(
        req_per_page * (page_no - 1),
        req_per_page * page_no
      );
      this.listSortData = resp;
    },

    ConvertUserField(field) {
      if (field != null) {
        let data = [
          { id: 1, lang: "120" }, //Text
          { id: 2, lang: "121" }, //Number
          { id: 3, lang: "123" }, //Currency
          { id: 4, lang: "137" }, //Date
          { id: 5, lang: "138" }, //Time
          { id: 6, lang: "144" }, //Date/Time
        ];
        let s = data.filter((elem) => elem.id == field);
        if (s.length != 0) {
          return typeof s !== "undefined" ? s[0].lang : "";
        } else {
          return "-";
        }
      } else if (field == null) {
        return "-";
      }
    },
    ConvertUserFieldType(field) {
      if (field != null) {
        let data = [
          { id: 3, lang: "135" }, //Variable
          { id: 1, lang: "136" }, //control
          { id: 0, lang: "136" }, //control
          { id: 4, lang: "137" }, //Lookup
          { id: 2, lang: "138" }, //Fixed
        ];
        let s = data.filter((elem) => elem.id == field);
        console.log(s[0].lang);
        return typeof s !== "undefined" ? s[0].lang : "";
      } else if (field == null) {
        return "-";
      }
    },
    PositionWorkPeriod(work) {
      const intPositionWorkPeriod = work.toString().split("");
      console.log(work.toString().length);
      if (work.toString().length == 6) {
        return (
          (intPositionWorkPeriod[0] != 0 ? intPositionWorkPeriod[0] : "") +
          (intPositionWorkPeriod[1] != 0 ? intPositionWorkPeriod[1] : "") +
          " " +
          (intPositionWorkPeriod[1] != 0 || intPositionWorkPeriod[2] != 0
            ? this.$t("93").toLowerCase()
            : "") +
          " " +
          (intPositionWorkPeriod[2] != 0 ? intPositionWorkPeriod[2] : "") +
          (intPositionWorkPeriod[3] != 0 ? intPositionWorkPeriod[3] : "") +
          " " +
          (intPositionWorkPeriod[2] != 0 || intPositionWorkPeriod[3] != 0
            ? this.$t("92").toLowerCase()
            : "") +
          " " +
          (intPositionWorkPeriod[4] != 0 ? intPositionWorkPeriod[4] : "") +
          (intPositionWorkPeriod[5] != 0 ? intPositionWorkPeriod[5] : "") +
          " " +
          (intPositionWorkPeriod[4] != 0 || intPositionWorkPeriod[5] != 0
            ? this.$t("219").toLowerCase()
            : "")
        );
      } else if (work.toString().length == 5) {
        return (
          (intPositionWorkPeriod[0] != 0
            ? intPositionWorkPeriod[0] + " " + this.$t("93").toLowerCase()
            : "") +
          " " +
          (intPositionWorkPeriod[1] != 0 ? intPositionWorkPeriod[1] : "") +
          (intPositionWorkPeriod[2] != 0 ? intPositionWorkPeriod[2] : "") +
          " " +
          (intPositionWorkPeriod[3] != 0 || intPositionWorkPeriod[4] != 0
            ? this.$t("92").toLowerCase()
            : "") +
          " " +
          (intPositionWorkPeriod[3] != 0 ? intPositionWorkPeriod[3] : "") +
          (intPositionWorkPeriod[4] != 0 ? intPositionWorkPeriod[4] : "") +
          " " +
          (intPositionWorkPeriod[3] != 0 || intPositionWorkPeriod[4] != 0
            ? this.$t("219").toLowerCase()
            : "")
        );
      }
    },
    ConvertParameterValueType(field) {
      if (field != null) {
        let data = [
          { id: null, lang: "79" }, //all
          { id: 1, lang: "120" }, //text
          { id: 2, lang: "121" }, //number
          { id: 3, lang: "122" }, //boolean
          { id: 4, lang: "123" }, //currency
        ];
        let s = data.filter((elem) => elem.id == field);
        return typeof s !== "undefined" ? s[0].lang : "";
      } else if (field == null) {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.sorted {
  transform: rotate(180deg) !important;
  position: relative;
}
.hidden-sort {
  display: none;
}
.table-mobile {
  max-height: calc(100vh - 330px) !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 303px);
  width: auto;
  .table {
    overflow-y: auto;
    // width: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 2;
      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $black-300;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
      tr {
        th {
          height: 48px;
        }
        th:first-child {
          position: sticky;
          left: 0;
          background-color: $white;
          z-index: 2;
        }
        th:last-child {
          position: sticky;
          right: 0;
          background-color: $white;
          z-index: 2;
        }
      }
    }
    tbody {
      tr {
        th:first-child {
          position: sticky;
          left: 0;
          background-color: #fff;
          z-index: 0;
        }
        th:last-child {
          z-index: 0;
          position: sticky;
          right: 0;
          background-color: #fff;
        }
        &:hover {
          th:first-child {
            background-color: $black-50 !important;
          }
          th:last-child {
            background-color: $black-50 !important;
          }
        }
      }
    }
    td {
      padding-right: 1rem;
      height: 44px;
      width: fit-content;
      // padding: 15px auto;
      div {
        white-space: nowrap;
      }
    }
    th {
      padding-right: 1rem;
      white-space: nowrap;
    }
    tr {
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}
.sorting-active {
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: $black-300 !important;
  }
}
.hover-none {
  &:hover {
    background: none !important;
  }
}

.overlay-in {
  opacity: 0.6;
  transition: 1s;
}
.overlay-out {
  opacity: 0;
  transition: 1s;
}
.rotate-180 {
  transform: rotate(180deg);
}
.fill-secondary {
  fill: $secondary !important;
}
// .contrue {
//   width: 100% !important;
// }
// .confalse {
//   width: fit-content !important;
// }
.none-scrollbar {
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.alert-box {
  background-color: rgba(255, 255, 255, 0.5);
  width: calc(100% - 220px);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}
.work-en {
  .item {
    width: 37px !important;
  }
}
.work-th {
  .item:first-child {
    width: 55px !important;
  }
  .item:last-child {
    width: 39px !important;
  }
  .item:nth-child(2) {
    width: 55px !important;
  }
}
</style>
